import React, { useEffect, useRef, useState } from "react";
import { Button, InputText, Toast } from "primereact";
import VUpload from "components/v-upload";
import SettingService from "services/settings";
import UploadService from "services/uploads";
import { showToast } from "utils/common";

const emptyData = {
  file: null,
  logo: null,
  logoResponsive: null,
  backgroundLogin: null,
};

const View = () => {
  const [state, setState] = useState<any>({});
  const toast = useRef(null);
  const [details, setDetails] = useState(emptyData);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state) {
      setDetails((prev) => ({
        ...prev,
        ...state,
      }));
    }
  }, [state]);

  const getData = async () => {
    try {
      const res: any = await SettingService.getSettingsByName({
        params: {
          name: "logos",
        },
      });
      if (res) {
        const { setting } = res;
        if (setting) {
          const value = setting["value"] ? setting["value"] : {};
          setState(value);
        }
      }
    } catch (error) {}
  };

  const onChange = async (name, value) => {
    if (name === "link") {
      setDetails((prev) => ({
        ...prev,
        backgroundLogin: value,
      }));
    } else {
      setDetails((prev) => ({
        ...prev,
        [name]: value.filePath,
      }));
    }
  };

  const submit = async () => {
    try {
      if (state) {
        await SettingService.updateSetting({
          body: {
            name: "logos",
            value: {
              ...details,
            },
          },
        });
      } else {
        await UploadService.create({
          body: {
            ...details,
          },
        });
      }
      showToast(toast, "success", "File saved!");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const handleCancel = () => {
    getData();
  };

  const convertIdVideo = (link: any) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = link?.match(regExp);
    if (match && match[2].length === 11) {
      return match[2];
    } else {
      //
    }
  };

  return (
    <div className="grid">
      <Toast ref={toast} />

      <div className="col-12">
        <div className="card">
          <div className="pb-3">
            <h4>Logo and background login</h4>
          </div>
          <div className="grid">
            <div className="col-6">
              <div className="flex justify-content-center font-bold">Logo</div>
              <VUpload
                urlFile={details.logo}
                setFile={(file) => onChange("logo", file)}
                size="240x50"
              />
            </div>
            <div className="col-6">
              <div className="flex justify-content-center font-bold">
                Logo responsive
              </div>
              <VUpload
                urlFile={details.logoResponsive}
                setFile={(file) => onChange("logoResponsive", file)}
                size="50x50"
              />
            </div>
            <div className="col-12 flex flex-column align-items-center">
              <div className="flex justify-content-center font-bold">
                Background Video
              </div>
              <VUpload
                fileType="video"
                urlFile={details.backgroundLogin}
                setFile={(file) => onChange("backgroundLogin", file)}
              />
              <br />
              <InputText
                id="youtube"
                value={details.backgroundLogin}
                placeholder="Link..."
                onChange={(e) => onChange("link", e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="column-12 w-full text-center">
        <Button
          className="p-button-danger mr-2"
          label="Cancel"
          onClick={handleCancel}
        />
        <Button
          className="p-button-success mr-2"
          label="Submit"
          onClick={submit}
        />
      </div>
    </div>
  );
};

export default View;
