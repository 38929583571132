/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { Image } from "primereact/image";
import { formatLocalTime, showToast } from "utils/common";
import Details from "./components/Details";
import VDialog from "components/v-dialog";
import VConfirm from "components/v-confirm";
import { Calendar } from "primereact/calendar";
import debounce from "lodash.debounce";
import { MultiSelect, Panel } from "primereact";
import InvestService from "services/invests";
import Terms from "./components/Terms";
import NumberEditorCell from "components/v-edit-cell/NumberEditorCell";

const STATUS = [
  { name: "Pending", value: "pending" },
  { name: "Opening", value: "opening" },
  { name: "Closed", value: "closed" },
];

const View = () => {
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState(null);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    status: STATUS.map((s) => s.value),
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: "order",
    sortOrder: 1,
  });
  const [totalRecords, setTotalRecords] = useState(0);

  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDelete = useRef(null);
  const refDialogDetail = useRef(null);
  const refDetail = useRef(null);

  const refDialogTerm = useRef(null);
  const refTerm = useRef(null);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  const loadData = async () => {
    try {
      const res: any = await InvestService.search({
        query: {
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
        },
      });
      if (res && res.content) {
        setData(res.content);
        setTotalRecords(res.total);
      } else setData([]);
    } catch (error) {
      setData([]);
    }
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  //CREATE OR NEW
  const openNew = () => {
    setDetail(null);
    refDialogDetail.current.show();
  };
  const editProduct = (data) => {
    try {
      setDetail({ ...data });
      refDialogDetail.current.show();
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };
  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };
  const onSumitDialogDetail = () => {
    refDetail.current.submit();
    if (!detail) refDialogDetail.current.close();
  };
  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  // Change order
  const onChangeOrder = async (item: any, value: any) => {
    let _data: any = { ...item, order: value };
    await InvestService.update({
      params: {
        id: item._id,
      },
      body: {
        ..._data,
      },
    });
    loadData();
  };

  //TERM
  const editTerm = (data) => {
    try {
      setDetail({ ...data });
      refDialogTerm.current.show();
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };
  const onCancelDialogTerm = () => {
    refDialogTerm.current.close();
  };
  const onSumitDialogTerm = () => {
    refTerm.current.submit();
  };
  const setLoadingSaveTerm = (flg) => {
    refDialogTerm.current.setLoading(flg);
  };

  // DELETE
  const confirmDelete = (product) => {
    setDetail(product);
    refDialogDelete.current.show();
  };
  const handleDelete = async () => {
    try {
      await InvestService.deleted({
        params: {
          id: detail._id,
        },
      });
      const _data = data.filter((val) => val._id !== detail._id);
      setData(_data);
      refDialogDelete.current.close();
      setDetail(null);
      showToast(toast, "success", "Deleted");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  //RENDER
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            // onClick={confirmDeleteSelected}
            // disabled={!selectedProducts || !selectedProducts.length}
            disabled={true}
          />
        </div>
      </React.Fragment>
    );
  };
  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          disabled
          className="mr-2 inline-block"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          disabled
          className="p-button-help"
          // onClick={exportCSV}
        />
      </React.Fragment>
    );
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="bx bx-file"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => editTerm(rowData)}
        />
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => editProduct(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-warning"
          onClick={() => confirmDelete(rowData)}
        />
      </div>
    );
  };

  const header = (
    <Panel header="Filter" toggleable collapsed={false}>
      <div className="flex  flex-column md:flex-row md:justify-content-between md:align-items-center">
        <div className="grid my-2 align-items-center w-full">
          <span className="block col-12 md:col-6 p-input-icon-left">
            <i className="ml-1 pi pi-search" />
            <InputText
              className="w-full"
              type="search"
              onInput={(e: any) => onSearch("search", e.target.value)}
              placeholder="Search..."
            />
          </span>{" "}
          <div className="col-12 md:col-6">
            <MultiSelect
              value={globalFilter.status}
              options={STATUS}
              onChange={(e: any) => onSearch("status", e.value)}
              optionLabel="name"
              placeholder="Status"
              maxSelectedLabels={3}
              className={"w-full"}
            />
          </div>
          <Calendar
            className="col-6"
            id="time24"
            placeholder="Start Date"
            value={globalFilter.start}
            onChange={(e: any) => onSearch("start", e.value)}
            showTime
            showSeconds
            showButtonBar
          />{" "}
          <Calendar
            className="col-6"
            id="time24"
            placeholder="End Date"
            value={globalFilter.end}
            onChange={(e: any) => onSearch("end", e.value)}
            showTime
            showSeconds
            showButtonBar
          />
        </div>
      </div>
    </Panel>
  );

  return (
    <div className="grid View-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            ref={dt}
            value={data}
            header={header}
            emptyMessage="No data found."
            dataKey="id"
            size="small"
            scrollDirection="both"
            className="datatable-responsive"
            scrollable
            showGridlines
            lazy
            //panigate
            paginator
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 20, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            //sort
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onSort={onSort}
          >
            <Column
              body={actionBodyTemplate}
              style={{ flexGrow: 1, flexBasis: "160px" }}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "80px" }}
              header="Image"
              body={(rowData) =>
                rowData.icon && (
                  <Image
                    src={rowData.icon}
                    alt="Image Text"
                    width="50"
                    height="50"
                    preview
                  />
                )
              }
            ></Column>
            <Column
              field="name"
              header="Name"
              style={{ flexGrow: 1, flexBasis: "350px" }}
            ></Column>
            <Column
              field="order"
              header="Order"
              sortable
              style={{ flexGrow: 1, flexBasis: "100px" }}
              body={(rowData, options) => (
                <NumberEditorCell
                  value={rowData.order}
                  options={options}
                  onChange={(e, value) => onChangeOrder(rowData, value)}
                />
              )}
            ></Column>
            <Column
              field="email"
              header="Owner"
              style={{ flexGrow: 1, flexBasis: "250px" }}
              body={(rowData) => String(rowData.user_id.email || "")}
            ></Column>
            <Column
              field="groups"
              header="Groups"
              style={{ flexGrow: 1, flexBasis: "450px" }}
              body={(rowData) => String(rowData.groups)}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "100px" }}
              field="type"
              header="Type"
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="status"
              header="Status"
              body={(rowData) => (
                <span
                  className={`v-badge status-${rowData.status.toLowerCase()}`}
                >
                  {rowData.status}
                </span>
              )}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="start_date"
              header="Start Date"
              sortable
              body={(rowData) => formatLocalTime(rowData.time.start_date)}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="end_date"
              header="End Date"
              sortable
              body={(rowData) => formatLocalTime(rowData.time.end_date)}
            ></Column>

            <Column
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="createdAt"
              header="Created At"
              sortable
              body={(rowData) => formatLocalTime(rowData.createdAt)}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "100px" }}
              field="category"
              header="Category"
            ></Column>
          </DataTable>

          <VDialog
            ref={refDialogDetail}
            header="Package Detail"
            onSubmit={onSumitDialogDetail}
          >
            <Details
              ref={refDetail}
              data={detail}
              toast={toast}
              reload={loadData}
              setLoading={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
            />
          </VDialog>

          <VDialog
            ref={refDialogTerm}
            header="Terms"
            onSubmit={onSumitDialogTerm}
          >
            <Terms
              ref={refTerm}
              data={detail}
              toast={toast}
              reload={loadData}
              setLoading={setLoadingSaveTerm}
              onCancel={onCancelDialogTerm}
            />
          </VDialog>

          <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(View, comparisonFn);
