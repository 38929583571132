/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useContext,
  useMemo,
} from "react";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import StockService from "services/stocks";
import { MasterContext } from "contexts/MasterContext";
import { MultiSelect } from "primereact";
import { Panel } from "primereact/panel";

const ALLOW_SHOW = ["top", "dashboard", "right"];
const NETWORK_SETTING = ["bsc", "ethereum", "tron", "trade"];

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const emptyData = {
    name: "",
    contract: "",
    symbol: "",
    supply: 0,
    logo: "",
    decimal: 0,
    status: "enable",
    allow_show: [],
    fee_transfer: {
      stock: "vndb",
      percent: 0,
    },
    fee_swap: {
      stock: "vndb",
      percent: 0,
    },
    fee_withdraw: {
      stock: "vndb",
      percent: 0,
    },
    pairs: [],
    sell_pairs: [],
    web: "",
    vat: 0,
    feature: {
      is_cv: false,
      is_qrmart: false,
      is_vdiarybook: false,
      is_withdraw: false,
      is_deposit: false,
      is_swap: false,
      is_sell: false,

      is_deposit_p2p: false,
      is_withdraw_p2p: false,
      is_deposit_spot: false,
      is_withdraw_spot: false,

      is_group: false,
      is_under_branch: false,
      is_transfer_to_trade: false,
      withdraw_trade_to_wallet: false,
    },
    network: [],
    locked: {
      profit: {
        status: false,
        percent: 0,
      },
      deposit: {
        status: false,
        percent: 0,
      },
      swap: {
        status: false,
        percent: 0,
      },
      revenue_system: {
        status: false,
        percent: 0,
      },
      revenue_campaign: {
        status: false,
        percent: 0,
      },
      trust_project_commission: {
        status: false,
        percent: 0,
      },
      transfer_to_trade: {
        status: false,
        percent: 0,
      },
      withdraw_trade_to_wallet: {
        status: false,
        percent: 0,
      },
    },
    limit_trade: {
      quantity_token: {
        buy: {
          min: 0,
          max: 0,
        },
        sell: {
          min: 0,
          max: 0,
        },
      },
      price: {
        buy: {
          min: 0,
          max: 0,
        },
        sell: {
          min: 0,
          max: 0,
        },
      },
    },
    limit: {
      max_withdraw: 0,
      min_withdraw: 0,
    },
    order: 0,
  };

  const { stocks, getStockPrices, stockPrices } = useContext(MasterContext);
  const [details, setDetails] = useState(emptyData);

  const vnexPrice = useMemo(() => {
    return stockPrices?.vnex?.vnd;
  }, [stockPrices]);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data)
      setDetails((prev) => ({
        ...prev,
        ...data,
      }));
  }, [data]);

  const onChange = (value, name, parent = null) => {
    if (parent) {
      setDetails({
        ...details,
        [parent]: {
          ...details[parent],
          [name]: value,
        },
      });
    } else
      setDetails({
        ...details,
        [name]: value,
      });
  };

  const onChangeLimitTrade = (field, type, name, value) => {
    setDetails({
      ...details,
      limit_trade: {
        ...details.limit_trade,
        [field]: {
          ...details.limit_trade[field],
          [type]: {
            ...details.limit_trade[field][type],
            [name]: value,
          },
        },
      },
    });
  };

  const onChangeLock = (type, name, value) => {
    setDetails((data) => ({
      ...data,
      locked: {
        ...data.locked,
        [type]: {
          ...data.locked[type],
          [name]: value,
        },
      },
    }));
  };

  // const onDropdownChange = (e, name) => {
  //   const val = e.value || "";
  //   let _details = { ...details };
  //   _details[`${name}`] = val;
  //   setDetails(_details);
  // };

  // const onInputCheckChange = (e, name) => {
  //   const val = e.value || false;
  //   let _details = { ...details };
  //   _details[`${name}`] = val;
  //   setDetails(_details);
  // };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await StockService.updateCryptos({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else {
        await StockService.createCryptos({
          body: {
            ...details,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Stock saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  useEffect(() => {
    getStockPrices();
  }, []);

  return (
    <div className="grid">
      <div className="col-12 ">
        {details.logo && (
          <img
            src={details.logo}
            alt={details.logo}
            width="150"
            className="mt-0 mx-auto mb-5 block shadow-2"
          />
        )}
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onChange(e.target.value, "name")}
          required
        />
      </div>
      <div className="field col-12 md:col-3">
        <label htmlFor="symbol">Symbol</label>
        <InputText
          id="symbol"
          value={details.symbol}
          onChange={(e) => onChange(e.target.value, "symbol")}
          required
        />
      </div>
      <div className="field col-12 md:col-3">
        <label htmlFor="symbol">Decimal</label>
        <InputText
          value={details.decimal}
          onChange={(e) => onChange(e.target.value, "decimal")}
          required
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="logo">Logo</label>
        <InputText
          id="logo"
          value={details.logo}
          onChange={(e) => onChange(e.target.value, "logo")}
          required
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="contract">Contract</label>
        <InputText
          id="contract"
          value={details.contract}
          onChange={(e) => onChange(e.target.value, "contract")}
          required
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Order</label>
        <InputNumber
          id="stock_order"
          value={details?.order}
          mode="decimal"
          onChange={(e) => onChange(e.value, "order")}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Status</label>
        <Dropdown
          value={details.status}
          options={[
            { label: "Enable", value: "enable" },
            { label: "Disable", value: "disable" },
          ]}
          onChange={(e) => onChange(e.value, "status")}
          optionLabel="label"
          placeholder="Select status"
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Allow show</label>
        <MultiSelect
          value={details.allow_show}
          options={ALLOW_SHOW.map((a) => ({
            label: a,
            value: a,
          }))}
          onChange={(e) => onChange(e.value, "allow_show")}
          placeholder="Allow show"
          maxSelectedLabels={3}
          className={"w-full"}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="web">Website</label>
        <InputText
          id="web"
          value={details.web}
          onChange={(e) => onChange(e.target.value, "web")}
          required
        />
      </div>

      <Panel
        header="Setting Network"
        toggleable
        className="my-2 field col-12 "
        collapsed={true}
      >
        <div className="grid">
          <div className="field col-12">
            <MultiSelect
              value={details?.network.length > 0 ? details.network : []}
              options={NETWORK_SETTING.map((n) => ({
                label: n,
                value: n,
              }))}
              onChange={(e) => onChange(e.value, "network")}
              placeholder="Select network"
              maxSelectedLabels={4}
              className={"w-full"}
            />
          </div>
        </div>
      </Panel>

      <Panel
        header="Setting Fee"
        toggleable
        className="my-2 field col-12 "
        collapsed={true}
      >
        <div className="grid">
          <div className="field col-12 md:col-6">
            <label htmlFor="description">Fee Swap (Stocks)</label>
            <Dropdown
              value={details?.fee_swap?.stock}
              options={stocks}
              onChange={(e) => onChange(e.value, "stock", "fee_swap")}
              optionLabel="symbol"
              optionValue="symbol"
              placeholder="Stocks"
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="description">Percent</label>
            <InputNumber
              id="fee_swap"
              prefix="%"
              value={details.fee_swap.percent}
              mode="decimal"
              minFractionDigits={2}
              onChange={(e) => onChange(e.value, "percent", "fee_swap")}
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="description">Fee Transfer (Stocks)</label>
            <Dropdown
              value={details.fee_transfer.stock}
              options={stocks}
              onChange={(e) => onChange(e.value, "stock", "fee_transfer")}
              optionLabel="symbol"
              optionValue="symbol"
              placeholder="Stocks"
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="description">Percent</label>
            <InputNumber
              id="fee_transfer"
              prefix="%"
              value={details.fee_transfer.percent}
              mode="decimal"
              minFractionDigits={2}
              onChange={(e) => onChange(e.value, "percent", "fee_transfer")}
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="description">Fee Withdraw (Stocks)</label>
            <Dropdown
              value={details.fee_withdraw.stock}
              options={stocks}
              onChange={(e) => onChange(e.value, "stock", "fee_withdraw")}
              optionLabel="symbol"
              optionValue="symbol"
              placeholder="Stocks"
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="description">Percent</label>
            <InputNumber
              id="fee_withdraw"
              prefix="%"
              value={details.fee_withdraw.percent}
              mode="decimal"
              minFractionDigits={2}
              onChange={(e) => onChange(e.value, "percent", "fee_withdraw")}
            />
          </div>
        </div>
      </Panel>

      <Panel
        header="Setting Limit"
        toggleable
        className="my-2 field col-12 "
        collapsed={true}
      >
        <div className="grid">
          <div className="field col-12 md:col-6">
            <label htmlFor="description">Min Withdraw </label>
            <InputNumber
              id="min_trade"
              value={details?.limit?.min_withdraw}
              onChange={(e) => onChange(e.value, "min_withdraw", "limit")}
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="description">Max Withdraw</label>
            <InputNumber
              id="max_trade"
              value={details?.limit?.max_withdraw}
              onChange={(e) => onChange(e.value, "max_withdraw", "limit")}
            />
          </div>
        </div>
      </Panel>

      <Panel
        header="Setting Trade"
        toggleable
        className="my-2 field col-12 "
        collapsed={true}
      >
        <div className="grid">
          <div className="field col-12 md:col-6">
            <label htmlFor="description">Min Token Buy</label>
            <InputNumber
              id="min_trade"
              value={details?.limit_trade?.quantity_token?.buy?.min}
              onChange={(e) =>
                onChangeLimitTrade("quantity_token", "buy", "min", e.value)
              }
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="description">Max Token Buy</label>
            <InputNumber
              id="max_trade"
              value={details?.limit_trade?.quantity_token?.buy?.max}
              onChange={(e) =>
                onChangeLimitTrade("quantity_token", "buy", "max", e.value)
              }
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="description">Min Token Sell</label>
            <InputNumber
              id="min_trade"
              value={details?.limit_trade?.quantity_token?.sell?.min}
              onChange={(e) =>
                onChangeLimitTrade("quantity_token", "sell", "min", e.value)
              }
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="description">Max Token Sell</label>
            <InputNumber
              id="max_trade"
              value={details?.limit_trade?.quantity_token?.sell?.max}
              onChange={(e) =>
                onChangeLimitTrade("quantity_token", "sell", "max", e.value)
              }
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="description">Min Price Buy </label>
            <InputNumber
              id="min_trade"
              value={details?.limit_trade?.price?.buy?.min * vnexPrice}
              mode="decimal"
              onChange={(e) =>
                onChangeLimitTrade("price", "buy", "min", e.value / vnexPrice)
              }
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="description">Max Price Buy </label>
            <InputNumber
              id="max_trade"
              value={details?.limit_trade?.price?.buy?.max * vnexPrice}
              onChange={(e) =>
                onChangeLimitTrade("price", "buy", "max", e.value / vnexPrice)
              }
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="description">Min Price Sell </label>
            <InputNumber
              id="min_trade"
              value={details?.limit_trade?.price?.sell?.min * vnexPrice}
              onChange={(e) =>
                onChangeLimitTrade("price", "sell", "min", e.value / vnexPrice)
              }
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="description">Max Price Sell </label>
            <InputNumber
              id="max_trade"
              value={details?.limit_trade?.price?.sell?.max * vnexPrice}
              mode="decimal"
              onChange={(e) =>
                onChangeLimitTrade("price", "sell", "max", e.value / vnexPrice)
              }
            />
          </div>
        </div>
      </Panel>

      <Panel
        header="Setting Feature"
        toggleable
        className="my-2 field col-12 "
        collapsed={true}
      >
        <div className="grid">
          {/* On off option */}
          <div className="field col-6 md:col-3">
            <label htmlFor="swap">Swap</label>
            <br />
            <InputSwitch
              checked={details.feature.is_swap}
              onChange={(e) => onChange(e.value, "is_swap", "feature")}
            />
          </div>
          <div className="field col-6 md:col-3">
            <label htmlFor="id_region">Withdraw</label>
            <br />
            <InputSwitch
              checked={details.feature.is_withdraw}
              onChange={(e) => onChange(e.value, "is_withdraw", "feature")}
            />
          </div>
          <div className="field col-6 md:col-3">
            <label htmlFor="id_province">Deposit</label>
            <br />
            <InputSwitch
              checked={details.feature.is_deposit}
              onChange={(e) => onChange(e.value, "is_deposit", "feature")}
            />
          </div>

          <div className="field col -6 md:col-3">
            <label htmlFor="is_swap">Deposit Spot</label>
            <br />
            <InputSwitch
              checked={details.feature.is_deposit_spot}
              onChange={(e) => onChange(e.value, "is_deposit_spot", "feature")}
            />
          </div>

          <div className="field col -6 md:col-3">
            <label htmlFor="is_swap">Withdraw Spot</label>
            <br />
            <InputSwitch
              checked={details.feature.is_withdraw_spot}
              onChange={(e) => onChange(e.value, "is_withdraw_spot", "feature")}
            />
          </div>

          <div className="field col-6 md:col-3">
            <label htmlFor="is_claim">Deposit P2P</label>
            <br />
            <InputSwitch
              checked={details.feature.is_deposit_p2p}
              onChange={(e) => onChange(e.value, "is_deposit_p2p", "feature")}
            />
          </div>
          <div className="field col-6 md:col-3">
            <label htmlFor="is_claim">Withdraw P2P</label>
            <br />
            <InputSwitch
              checked={details.feature.is_withdraw_p2p}
              onChange={(e) => onChange(e.value, "is_withdraw_p2p", "feature")}
            />
          </div>

          <div className="field col-6 md:col-3">
            <label htmlFor="is_deposit">CV</label>
            <br />
            <InputSwitch
              checked={details.feature.is_cv}
              onChange={(e) => onChange(e.value, "is_cv", "feature")}
            />
          </div>
          <div className="field col-6 md:col-3">
            <label htmlFor="is_withdraw">QR Mart</label>
            <br />
            <InputSwitch
              checked={details.feature.is_qrmart}
              onChange={(e) => onChange(e.value, "is_qrmart", "feature")}
            />
          </div>
          <div className="field col-6 md:col-3">
            <label htmlFor="is_withdraw">VDIARYBOOK</label>
            <br />
            <InputSwitch
              checked={details.feature.is_vdiarybook}
              onChange={(e) => onChange(e.value, "is_vdiarybook", "feature")}
            />
          </div>
          <div className="field col-6 md:col-3">
            <label htmlFor="is_sell">Sell</label>
            <br />
            <InputSwitch
              checked={details.feature.is_sell}
              onChange={(e) => onChange(e.value, "is_sell", "feature")}
            />
          </div>
          <div className="field col-6 md:col-3">
            <label htmlFor="is_withdraw">Is Group</label>
            <br />
            <InputSwitch
              checked={details.feature.is_group}
              onChange={(e) => onChange(e.value, "is_group", "feature")}
            />
          </div>
          <div className="field col-6 md:col-3">
            <label htmlFor="is_sell">Is Under Branch</label>
            <br />
            <InputSwitch
              checked={details.feature.is_under_branch}
              onChange={(e) => onChange(e.value, "is_under_branch", "feature")}
            />
          </div>
          <div className="field col-6 md:col-3">
            <label htmlFor="is_transfer_to_trade">
              Transfer to Trade Balance
            </label>
            <br />
            <InputSwitch
              checked={details.feature.is_transfer_to_trade}
              onChange={(e) =>
                onChange(e.value, "is_transfer_to_trade", "feature")
              }
            />
          </div>
          {/* <div className="field col-6 md:col-3">
            <label htmlFor="is_transfer_to_trade">
              Withdraw Trade To Wallet
            </label>
            <br />
            <InputSwitch
              checked={details.feature.withdraw_trade_to_wallet}
              onChange={(e) =>
                onChange(e.value, "withdraw_trade_to_wallet", "feature")
              }
            />
          </div> */}
        </div>
      </Panel>
      <Panel
        header="Setting Lock"
        toggleable
        className="my-2 field col-12 "
        collapsed={true}
      >
        <div className="grid">
          {Object.keys(details.locked).map((key) => {
            return (
              <>
                <div className="field col-8 md:col-9">
                  <label htmlFor="description">Percent Lock ({key})</label>
                  <InputNumber
                    prefix="%"
                    value={details.locked[key].percent}
                    mode="decimal"
                    minFractionDigits={2}
                    onChange={(e) => onChangeLock(key, "percent", e.value)}
                    max={100}
                  />
                </div>
                <div className="field col-4 md:col-3">
                  <label htmlFor="swap">Lock ({key})</label>
                  <br />
                  <InputSwitch
                    checked={details.locked[key].status}
                    onChange={(e) => onChangeLock(key, "status", e.value)}
                  />
                </div>
              </>
            );
          })}
        </div>
      </Panel>
    </div>
  );
};

export default forwardRef(Details);
